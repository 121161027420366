import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
//import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        {/*Before:*/}
        {/*<NavMenu />*/}
        {/*<Container tag="main">*/}
        {/*  {this.props.children}*/}
        {/*</Container>*/}

        <Container fluid={true} >
          <div className="p-5 m-5 bg-light">
            {this.props.children}
          </div>
          <Row noGutters={true}>

          </Row>

        </Container>

      </div>
    );
  }
}
